<template>
    <div>
        <b-sidebar id="add_erp_invoice" aria-labelledby="sidebar-no-header-title" no-header backdrop width="90rem" :style="`direction:`+lang.ldir" right :title="lang.add_direct_invoice" shadow >
            <template #default="{ hide }">
            <div class="m-1 text-start" style="direction:rtl">
                <div style="width:140px;margin-right:10px;background:#000;color:#FFF;border-radius:5px" class="btn-primary btn-sm text-center">
                    <span>{{lang.add_invoice}}</span>
                </div>
                <div @click="hide" id="HideERPInvoice" style="width:100px;text-align:center;float:left;margin-left:5px;margin-top:-25px;cursor:pointer" class="btn-danger btn-sm">
                    <span>{{lang.close}}</span>
                </div>
            </div>
            <v-divider></v-divider>
            <div class="px-3 py-2" style="direction:rtl">
                <div v-if="showClientTypeRow" class="tobbuttom backBlack" :style="`direction:${lang.dir};padding:7px;width:100%;text-align:center;`">
                    <b-button class="customerTitle">{{lang.customer_type}}</b-button>
                    <b-button :class="`customerIndiv dispFlex btn btn-small redColor borderRad_`+lang.algin" id="_cIndS" @click="changeCusType(1)" >{{lang.individual_client}} <i id="_indivS"  style="font-size:1.1rem !important;margin-inline-start:10px;" class="fas fa-arrow-down"></i></b-button>
                    <b-button :class="`customerComp dispFlex btn btn-small weightColor borderRad_none`" id="_cComS" @click="changeCusType(2)" >{{lang.company_clinet}} <i id="_compsS"  style="font-size:1.1rem !important;margin-inline-start:10px;" class="fas fa-arrow-down hideme"></i></b-button>
                    <b-button :class="`customerGov dispFlex btn btn-small weightColor borderRad_`+lang.lalgin" id="_cGovS" @click="changeCusType(3)" >{{lang.government_clinet}} <i id="_govesS" style="font-size:1.1rem !important;margin-inline-start:10px;" class="fas fa-arrow-down hideme"></i></b-button>
                </div>
                <v-row>
                    <v-col cols="12" md="2" sm="6" xs="12">
                        <label>{{lang.invoice_type}}</label>
                        <b-input-group>
                        <b-form-select
                            class="selborder"
                            v-model="invoice.invtype"
                            :options="invtypes"
                            @change="InvType()"
                            style="background:green !important"
                            ></b-form-select>
                        <b-input-group-append style="margin-right:-20px;margin-top:5px;">
                            <i class="fas fa-arrow-down"></i>
                            </b-input-group-append>
                        </b-input-group>
                    </v-col>
                    <v-col cols="12" md="2" sm="6" xs="12">
                        <label>{{lang.vat_type}}</label>
                        <b-input-group>
                        <b-form-select
                            class="selborder"
                            v-model="invoice.vtype"
                            :options="vattype"
                            @change="changeVatType()"
                            style="background:red !important"
                            ></b-form-select>
                            <b-input-group-append style="margin-right:-20px;margin-top:5px;">
                            <i class="fas fa-arrow-down"></i>
                            </b-input-group-append>
                        </b-input-group>
                    </v-col>
                    <v-col cols="12" md="2" sm="6" xs="12">
                        <label>{{lang.payment_method}}</label>
                        <b-input-group>
                        <b-form-select
                            class="selborder"
                            v-model="invoice.paytype"
                            :options="paytypes"
                            @change="changePtype()"
                            ></b-form-select>
                        <b-input-group-append style="margin-right:-20px;margin-top:5px;">
                            <i class="fas fa-arrow-down"></i>
                            </b-input-group-append>
                        </b-input-group>
                    </v-col>
                    
                    <v-col cols="12" md="2" sm="6" xs="12">
                        <label>{{lang.saler_name}}</label>
                        <b-input-group>
                        <b-form-select
                            class="selborder"
                            v-model="invoice.userid"
                            :options="users"
                            ></b-form-select>
                        <b-input-group-append style="margin-right:-20px;margin-top:5px;">
                            <i class="fas fa-arrow-down"></i>
                            </b-input-group-append>
                        </b-input-group>
                    </v-col>
                    <v-col cols="12" md="2" sm="6" xs="12">
                        <label>{{lang.saler_name}}</label>
                        <b-form-input
                            type="date"
                            class="inborder"
                            v-model="invoice.inv_date"
                            />
                    </v-col>
                    <v-col cols="12" md="2" sm="6" xs="12">
                        <label>{{lang.store}}</label>
                        <b-input-group>
                        <b-form-select
                            class="selborder"
                            v-model="invoice.storeid"
                            :options="storeIds"
                            
                            ></b-form-select>
                        <b-input-group-append style="margin-right:-20px;margin-top:5px;">
                            <i class="fas fa-arrow-down"></i>
                            </b-input-group-append>
                        </b-input-group>
                    </v-col>
                    <v-col cols="12" md="2" sm="6" xs="12">
                        <label>{{lang.work_type}}</label>
                        <b-input-group>
                        <b-form-select
                            class="selborder"
                            v-model="invoice.work_type"
                            style="background:green !important"
                            :options="workTypes"
                            ></b-form-select>
                        <b-input-group-append style="margin-right:-20px;margin-top:5px;">
                            <i class="fas fa-arrow-down"></i>
                            </b-input-group-append>
                        </b-input-group>
                    </v-col>
                    
                </v-row>
                <v-row>
                    <v-col cols="12" md="2" sm="4" xs="12">
                        <label>{{lang.mobile}}</label>
                        <b-form-input
                            class="inborder"
                            v-model="customer.mobile"
                            id="CusMobile"
                            @change="getName()" />
                    </v-col>
                    <v-col cols="12" md="2" sm="4" xs="12">
                        <label>{{lang.full_name}}</label>
                        <b-form-input
                            class="inborder"
                            v-model="customer.full_name"  />
                    </v-col>
                    <v-col cols="12" md="2" sm="4" xs="12" v-if="customer.customer_type == 2">
                        <label>{{lang.company_name}}</label>
                        <b-form-input
                            class="inborder"
                            v-model="customer.company_name"  />
                    </v-col>
                    <v-col cols="12" md="2" sm="4" xs="12" v-if="customer.customer_type == 2">
                        <label>{{lang.company_vatid}}</label>
                        <b-form-input
                            class="inborder"
                            v-model="customer.company_vatid"  />
                    </v-col>
                    <v-col cols="12" md="2" sm="4" xs="12" v-if="customer.customer_type == 2">
                        <label>{{lang.crt_number}}</label>
                        <b-form-input
                            class="inborder"
                            v-model="customer.crt_number"  />
                    </v-col>
                    <v-col cols="12" md="2" sm="4" xs="12" v-if="customer.customer_type == 2">
                        <label>{{lang.branch_number}}</label>
                        <b-form-input
                            class="inborder"
                            v-model="customer.branch_number"  />
                    </v-col>
                    <v-col cols="12" md="2" sm="4" xs="12" v-if="customer.customer_type == 2">
                        <label>{{lang.city}}</label>
                        <b-form-input
                            class="inborder"
                            v-model="customer.city"  />
                    </v-col>
                    <v-col cols="12" md="2" sm="4" xs="12" v-if="customer.customer_type == 2">
                        <label>{{lang.street_name}}</label>
                        <b-form-input
                            class="inborder"
                            v-model="customer.street_name"  />
                    </v-col>
                    <v-col cols="12" md="2" sm="4" xs="12" v-if="customer.customer_type == 2">
                        <label>{{lang.building_no}}</label>
                        <b-form-input
                            class="inborder"
                            v-model="customer.building_no"  />
                    </v-col>
                    <v-col cols="12" md="2" sm="4" xs="12" v-if="customer.customer_type == 2">
                        <label>{{lang.district}}</label>
                        <b-form-input
                            class="inborder"
                            v-model="customer.district"  />
                    </v-col>
                    <v-col cols="12" md="2" sm="4" xs="12" v-if="customer.customer_type == 2">
                        <label>{{lang.zipcode}}</label>
                        <b-form-input
                            class="inborder"
                            v-model="customer.zipcode"  />
                    </v-col>
                    <v-col cols="12" md="2" sm="4" xs="12" v-if="customer.customer_type == 3">
                        <label>{{lang.entity_name}}</label>
                        <b-form-input
                            class="inborder"
                            v-model="customer.entity_name"  />
                    </v-col>
                    <v-col cols="12" md="2" sm="4" xs="12" v-if="customer.customer_type == 3">
                        <label>{{lang.branch_name}}</label>
                        <b-form-input
                            class="inborder"
                            v-model="customer.branch_name"  />
                    </v-col>
                    <v-col cols="12" md="2" sm="12" xs="12" v-if="customer.customer_type == 3">
                        <label>{{lang.request_number}}</label>
                        <b-form-input
                            class="inborder"
                            v-model="invoice.request_number"
                            ></b-form-input>
                    </v-col>
                    <v-col cols="12" md="2" sm="12" xs="12" v-if="customer.customer_type == 3">
                        <label>{{lang.order_number}}</label>
                        <b-form-input
                            class="inborder"
                            v-model="invoice.order_number"
                            ></b-form-input>
                    </v-col>
                    <v-col cols="12" md="2" sm="12" xs="12" v-if="customer.customer_type == 3">
                        <label>{{lang.po_number}}</label>
                        <b-form-input
                            class="inborder"
                            v-model="invoice.po_number"
                            ></b-form-input>
                    </v-col>
                    <v-col cols="12" md="2" sm="12" xs="12" v-if="customer.customer_type == 3">
                        <label>{{lang.po_date}}</label>
                        <b-form-input
                            class="inborder"
                            type="date"
                            v-model="invoice.po_number"
                            />
                    </v-col>
                </v-row>
                <div class="mt-5">
                    <v-simple-table striped hover style="width:100%;">
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="backBlack">{{lang.item_code}}</th>
                                    <th style="width:20%" class="text-center backBlack" >{{lang.item_name}}</th>                                    
                                    <th style="width:20%" class="text-center backBlack" >{{lang.description}}</th>                                    
                                    <th class="text-center backBlack">{{lang.item_unit}}</th>
                                    <th class="text-center backBlack">{{lang.qtty}}</th>
                                    <th class="text-center backBlack">{{lang.item_price}}</th>
                                    <th class="text-center backBlack">{{lang.discount}}</th>
                                    <th class="text-center backGreen">{{lang.total_without_vat}}</th>
                                    <th class="text-center backRed">{{lang.vat}}</th>
                                    <th class="text-center backBlack">{{lang.ftotal}}</th>
                                    <th class="text-center backBlack">{{lang.action}}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item,index) in itemsRows" :key="index">
                                    <td>{{ item.itemcode }}</td>
                                    <td>{{ item.item_name }}</td>
                                    <td>{{ item.description }}</td>
                                    <td>{{ getUnit(item.unit_id) }}</td>
                                    <td>{{ item.qty }}</td>
                                    <td>{{ item.item_price }}</td>
                                    <td>{{ item.discount }}</td>
                                    <td>{{ item.total }}</td>
                                    <td>{{ item.vat }}</td>
                                    <td>{{ item.ftotal }}</td>
                                    <td style="">
                                        <b-button type="button" variant="danger" style="padding:0.15rem 0.5rem !important;font-weight:bolder;width:80px;background:red;border:none" @click="removeItem1(index)">حذف</b-button>
                                    </td>
                                </tr>
                                <tr>
                                    <td style="width:150px;" v-if="$store.state.licenseType.showItemCode">
                                        <b-form-input
                                            class="inborder"
                                            v-model="newrow.itemcode"
                                            @change="getItems()"
                                            >
                                        </b-form-input>
                                    </td>
                                    <td>
                                        <v-combobox
                                            v-model="newrow.item_name"
                                            :items="itemsworkdes"
                                            small-chips
                                            
                                        ></v-combobox>
                                    </td>
                                    <td>
                                        <b-form-textarea
                                            v-model="newrow.description"
                                        />
                                    </td>
                                    <td>
                                        <b-form-select class="selborder" v-model="newrow.unit_id" :options="itemUnitsList" @change="getPrices()" />
                                    </td>
                                    <td>
                                        <b-form-input
                                            class="inborder"
                                            v-model="newrow.qty"
                                            @change="calcKit()"
                                            id="newitemqty"
                                        ></b-form-input>
                                    </td>
                                    <td>
                                        <b-form-input
                                            class="inborder"
                                            v-model="newrow.item_price"
                                            @change="calcKit()"
                                        ></b-form-input>
                                    </td>
                                    <td>
                                        <b-form-input
                                            class="inborder"
                                            v-model="newrow.discount"
                                            @change="calcKit()"
                                        ></b-form-input>
                                    </td>
                                    <td>
                                        <b-form-input
                                            class="inborder"
                                            v-model="newrow.total"
                                            @change="calcKit()"
                                            readonly
                                        ></b-form-input>
                                    </td>
                                    <td>
                                        <b-form-input
                                            class="inborder"
                                            v-model="newrow.vat"
                                            @change="calcKit()"
                                            readonly
                                        ></b-form-input>
                                    </td>
                                    <td>
                                        <b-form-input
                                            class="inborder"
                                            v-model="newrow.ftotal"
                                            @change="calcKit()"
                                            readonly
                                        ></b-form-input>
                                    </td>
                                    <td class="text-center" style="">
                                        <b-button type="button" class="btn-sm" variant="success" style="width:80px;font-weight:bolder;background:green;border:none;" @click="addNewRow()">{{lang.add}}</b-button>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th class="text-center backEmpty"></th>
                                    <th class="text-center backEmpty"></th>
                                    <th class="text-center backEmpty"></th>
                                    <th class="text-center backEmpty"></th>
                                    <th class="text-center backEmpty"></th>
                                    <th class="text-center backEmpty"></th>
                                        <th class="text-center backBlack">{{lang.total}}</th>
                                    <th class="text-center backGreen">{{ sp.total }}</th>
                                    <th class="text-center backRed">{{ sp.vat }}</th>
                                    <th class="text-center backBlack">{{ sp.ftotal }}</th>
                                    <th class="text-center backEmpty"></th>
                                </tr>
                            </tfoot>
                        </template>
                    </v-simple-table>
                </div>
            </div>
            </template>
            <template #footer="" class="shadow">
                <div class="d-flex text-light align-items-center px-3 py-5">
                    <b-button type="button" @click="addInvoicex()"  id="addBTN" variant="success" class="ma-2 py-3 btn-sm" style="width:170px;font-size:1.3rem !important;">{{lang.save_n_print}}</b-button>
                    <b-button type="button" v-b-modal.add_addporch id="addModalBTN" variant="success" class="ma-2 py-3 btn-sm" style="display:none;width:170px;font-size:1.3rem !important;">{{lang.save_n_print}}</b-button>
                </div>
            </template>
        </b-sidebar>
        <invoice-add-approve ref="InvoiceAddApprove" />
    </div>
</template>

<script>
import axios from 'axios'
import {SnotifyPosition} from 'vue-snotify'
import InvoiceAddApprove from '../invoiceAddApprove.vue';

export default {
    components: {
        InvoiceAddApprove
    },
    data() {
        return {
            itemsworkdes: [],
            showClientTypeRow: true,
            customer: {
                mobile: '',
                address: '',
                branch_name: '',
                branch_number: '',
                building_no: '',
                city: '',
                company_name: '',
                company_vatid: '',
                crt_number: '',
                ctype: 1,
                customer_type: 1,
                district: '',
                entity_name: '',
                full_name: '',
                id: 0,
                id_number: '',
                street_name: '',
                zipcode: '',
            },
            invoice: {
                storeid: '',
                card_type: 1,
                old_invoice: 0,
                carsnumber: 1,
                owner_name: "",
                date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
                payment:{
                    cash: 0,
                    span: 0
                },
                billid: 1, 
                discount:0 ,
                t_discount:0 ,
                vtype:2,
                engine_meter: '',
                work_type: 1,
                next_oil_change: '',
                invtype:1,
                paytype: 2,
                paytypes: [],
                userid: 0,
                users: [],
                notes: '',
                insurance: 0,

            },
            newrow: {
                itemcode: '',
                item_name: '',
                description: '',
                unit_id: '',
                qty: '',
                item_price: '',
                discount: '',
                total: "",
                vat: '',
                ftotal: ''
            },
            paytypes: [],
            users: [],
            allProducts: [],
            spareparts: [],
            calenderTypes: [],
            itemUnits: [],
            itemUnitsList: [],
            itemsRows: [],
            stores: [],
            
        };
    },
    computed: {
        sp: function(){
            let t = {
                total: 0,
                vat: 0,
                ftotal: 0
            }
            for(let i=0;i<this.itemsRows.length;i++){
                t.total = t.total + parseFloat(this.itemsRows[i].total);
                t.vat = t.vat + parseFloat(this.itemsRows[i].vat);
                t.ftotal = t.ftotal + parseFloat(this.itemsRows[i].ftotal);
            }
            return t;
        },
        lang: function () {
            if (this.$cookies.get(this.$store.state.LangCooki) != null) {
                if (this.$cookies.get(this.$store.state.LangCooki) == 'en') {
                    return this.$store.state.lang.en;
                }
                else {
                    return this.$store.state.lang.ar;
                }
            }
            else if (this.$store.state.deflang == 'en') {
                return this.$store.state.lang.en;
            }
            else {
                return this.$store.state.lang.ar;
            }
        },
        invtypes: function(){
            return [
                {
                    text: this.lang.none_paid_invoice,
                    value: 2,
                },
                {
                    text: this.lang.paid_invoice,
                    value: 1,
                },
            ]
        },
        vattype: function(){
            if(this.$store.state.settings.vat_number == ''){
                return [
                    {
                        text: this.lang.prices_without_vat,
                        value: 1,
                    },
                ]
            }else{
                return [
                    {
                        text: this.lang.prices_include_vat,
                        value: 3,
                    },
                    {
                        text: this.lang.prices_exclusive_vat,
                        value: 2,
                    },
                    {
                        text: this.lang.prices_without_vat,
                        value: 1,
                    },
                ]
            }
        },
        workTypes: function(){
            let t = [];

            for(let i=0;i<this.calenderTypes.length;i++){
                t.push({
                    text: this.calenderTypes[i][`name_${this.lang.langname}`],value: this.calenderTypes[i].value
                })
            }
            return t;
        },
        storeIds: function(){
            let t = [];

            for(let i=0;i<this.stores.length;i++){
                t.push({
                    text: this.stores[i][`name_${this.lang.langname}`],value: this.stores[i].value
                })
            }
            return t;
        },
        
    },
    methods: {
        getStores(){
            const post = new FormData();
            post.append("type","getStores");
            post.append('auth',this.$cookies.get(this.$COOKIEPhase));
            post.append('data[id]',-1);
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {
                this.stores = response.data.results.data;
            });
        },
        changeVatType(){
            this.calcKit();
            this.calcAll();
        },
        getUnit(id){
            for(let i=0;i<this.itemUnits.length;i++){
                if(id == this.itemUnits[i].unit_id){
                    return this.itemUnits[i][`name_`+this.lang.langname]
                }
                
            }
        },
        removeItem1(index){
            this.itemsRows.splice(index,1)
        },
        LitemUnitsList(){
            let t = []
            for(let i=0;i<this.itemUnits.length;i++){
                t.push({
                    text: this.itemUnits[i][`name_`+this.lang.langname],
                    value: this.itemUnits[i].unit_id
                })
            }
            this.itemUnitsList = t;
        },
        getPrices(){
            for(let i=0;i<this.itemUnits.length;i++){
                if(this.newrow.unit_id == this.itemUnits[i].unit_id){
                    this.newrow.item_price = parseFloat(this.itemUnits[i].default_price)
                }
            }
            this.calcKit();
        },
        calcAll(){
            let t = {
                total: 0,
                ftotal: 0,
                vat: 0,
            }
            let x = this.itemsRows;
            for(let i=0;i<this.itemsRows;i++){
                let price = this.itemsRows[i].item_price;
                if(this.itemsRows[i].discount != 0){
                    price = parseFloat(this.itemsRows[i].item_price) - parseFloat(this.itemsRows[i].discount)
                }
                const vt = this.invoice.vtype;
                const prices = parseFloat(price) * parseFloat(this.itemsRows[i].qty);
                const vat = this.$calcVat(prices,vt);
                this.itemsRows[i].total = this.$RoundNum(vat.tot);
                this.itemsRows[i].ftotal = this.$RoundNum(vat.ftot);
                this.itemsRows[i].vat = this.$RoundNum(vat.vat);
                t.total = parseFloat(t.total) + parseFloat(this.itemsRows[i].total)
                t.vat = parseFloat(t.vat) + parseFloat(this.itemsRows[i].vat)
                t.ftotal = parseFloat(t.ftotal) + parseFloat(this.itemsRows[i].ftotal)
            } 
        },
        calcKit(){
            if(this.newrow.item_price == ''){
                this.newrow.total = 0
                this.newrow.vat = 0
                this.newrow.ftotal = 0
                return false;
            }
            let price = this.newrow.item_price
            if(this.newrow.discount == '')
            {
                this.newrow.discount = 0;
            }
            else{
                price = parseFloat(this.newrow.item_price) - parseFloat(this.newrow.discount)
            }
            if(this.newrow.qty == '')
            {
                this.newrow.qty = 1;
            }
            const vt = this.invoice.vtype;
            const prices = parseFloat(price) * parseFloat(this.newrow.qty);
            const vat = this.$calcVat(prices,vt);
            this.newrow.total = this.$RoundNum(vat.tot);
            this.newrow.ftotal = this.$RoundNum(vat.ftot);
            this.newrow.vat = this.$RoundNum(vat.vat);
            
        },
        addNewRow(){
            if(this.newrow.item_price == '' || this.newrow.item_name == ''){
               return false;
            }
            this.itemsRows.push(this.newrow);
            this.resetNew();
        },
        getItems() {
            const post = new FormData();
            post.append("type","getProducts");
            post.append('auth',this.$cookies.get(this.$COOKIEPhase));
            post.append('data[item_number]',this.newrow.itemcode);
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {
                
                if(response.data.results.data.length > 0){
                    if(response.data.results.data[0].item_type == 1){
                        this.newrow.itemcode = response.data.results.data[0].item_code;
                        this.newrow.item_name = response.data.results.data[0].item_namear;
                        this.newrow.item_price = response.data.results.data[0].default_price;
                        this.newrow.unit_id = response.data.results.data[0].main_unit;
                        this.itemUnits = response.data.results.data[0].itemUnits;
                        this.LitemUnitsList()
                    }else{
                        this.newrow.itemcode = '';
                        let message = this.lang.this_item_service_item;
                        this.$snotify.error(message, this.lang.alert, {
                            timeout: 1000000,
                            showProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            position: SnotifyPosition.centerCenter,
                            buttons: [
                                {
                                    text: this.lang.close, 
                                    action: (toast) => {
                                        this.$snotify.remove(toast.id); 
                                    } 
                                },
                            ]
                        });
                        return false;
                    }
                }
                this.calcKit();
            })
        },
        changeCusType(id){
            const _cInd = document.getElementById("_cIndS");
            const _cCom = document.getElementById("_cComS");
            const _cGov = document.getElementById("_cGovS"); 
            const _cIndS = document.getElementById("_indivS");
            const _cComS = document.getElementById("_compsS");
            const _cGovS = document.getElementById("_govesS"); 
            
            
            if(id == 1){
                _cInd.classList.remove('weightColor');
                _cInd.classList.add('redColor');
                _cIndS.classList.remove('hideme');
            }else{
                _cInd.classList.remove('redColor');
                _cInd.classList.add('weightColor');
                _cIndS.classList.add('hideme');
            }
            if(id == 2){
                _cCom.classList.remove('weightColor');
                _cCom.classList.add('redColor');
                _cComS.classList.remove('hideme');
            }else{
                _cCom.classList.remove('redColor');
                _cCom.classList.add('weightColor');
                _cComS.classList.add('hideme');
            }
            if(id == 3){
                _cGov.classList.remove('weightColor');
                _cGov.classList.add('redColor');
                _cGovS.classList.remove('hideme');
            }else{
                _cGov.classList.remove('redColor');
                _cGov.classList.add('weightColor');
                _cGovS.classList.add('hideme');
            }
            alert(id);
            this.customer.customer_type = id;
            this.customer.ctype = id;
        },
        getName() {
            //
        },
        getWorkType(){
            const post = new FormData();
            post.append('type','getStaticOptions')
            post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[lang]',this.$cookies.get(this.$store.state.LangCooki));
            post.append('data[phrase]','calendarTypes')
            axios.post(
                this.$store.state.SAMCOTEC.r_path,post
            ).then((response) => {
                if(response && response.data){
                    this.calenderTypes = response.data.results.data
                }
            })
            
        },
        changeAdduserInfo(){
            const cook = this.$cookies.get(this.$store.state.COOKIEPhase).split('||');
            this.invoice.userid = cook[0]
        },
        getInvSettings() {
            if(this.$store.state.settings.vat_number == ''){
                this.invoice.vtype = 1;
            }
            const post = new FormData();
            post.append('type','getInvSettings');
            post.append('auth',this.$cookies.get(this.$COOKIEPhase));
            post.append('data[all]','all');
            axios.post(
                this.$SAMCOTEC.r_path, post
            ).then(
                (response) => {
                    const res = response.data;
                    if(res != '' && res.error.number == 200){
                        if(res.results.data.users.length != 0){
                            for(let i= 0; i < res.results.data.users.length; i++){
                                this.users.push({
                                    text: res.results.data.users[i].full_name,
                                    value: res.results.data.users[i].id
                                })
                            }
                        }
                        if(res.results.data.desk.length != 0){
                            this.itemsworkdes = [];
                            this.allDesc = res.results.data.desk;
                            for(let i= 0; i < res.results.data.desk.length; i++){
                                this.itemsworkdes.push(res.results.data.desk[i].title);
                            }
                        }
                        if(res.results.data.products.length != 0){
                            this.allProducts = res.results.data.products;
                            this.spareparts = [];
                            for(let i= 0; i < res.results.data.products.length; i++){
                                this.spareparts.push(res.results.data.products[i].name);
                            }
                        }
                    }
                }
            )
        },
        getPyamentType(){
            const post = new FormData();
            post.append('type','getPayTypes');
            post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[id]',"all");
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {
                const res = response.data;
                this.payTypeOp = [];
                this.paytypes = [];
                for(let i=0;i<res.results.data.length;i++){
                    this.paytypes.push({
                        text: this.lang.langname == 'ar' ? res.results.data[i].type_name : res.results.data[i].type_name_en,
                        value: res.results.data[i].id
                    })
                }
                // this.paytypeOp = t;

                this.invoice.paytype = res.results.data[0].id;
            })
        },
        resetNew(){
            this.newrow = {
                itemcode: '',
                item_name: '',
                description: '',
                unit_id: '',
                qty: '',
                item_price: '',
                discount: '',
                total: "",
                vat: '',
                ftotal: ''
            }
        },
        reset() {
            this.customer = {
                mobile: '',
                address: '',
                branch_name: '',
                branch_number: '',
                building_no: '',
                city: '',
                company_name: '',
                company_vatid: '',
                crt_number: '',
                ctype: 1,
                customer_type: 1,
                district: '',
                entity_name: '',
                full_name: '',
                id: 0,
                id_number: '',
                street_name: '',
                zipcode: '',
            }
            this.invoice = {
                storeid: '',
                card_type: 1,
                old_invoice: 0,
                carsnumber: 1,
                owner_name: "",
                inv_date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
                billid: 1, 
                discount:0 ,
                t_discount:0 ,
                vtype:2,
                engine_meter: '',
                work_type: 1,
                next_oil_change: '',
                invtype:1,
                paytype: 2,
                userid: this.$store.state.userinfo.id,
                notes: '',
                insurance: 0,
                request_number: '',
                order_number: '',
                po_number: '',
                po_date: '',
            }
            this.resetNew()
            this.changeCusType(1);
            this.changeAdduserInfo();
            this.getPyamentType()
            this.getInvSettings()
            this.getWorkType()
            
        },
        addInvoicex(){
            let message = '';
            let doIt = true;
            if(this.invoice.storeid == ''){
                message = this.lang.select_store_before_continue
                doIt = false;
            }
            else if(this.itemsRows.length == 0){
                message = this.lang.please_add_one_item_before_continue
                doIt = false;
            }
            else if(this.customer.customer_type == 2 && 
                    (this.customer.company_name == '' || 
                    this.customer.address == '') 
            ){
                message = this.lang.company_name_and_company_address_required
                doIt = false;
            }
            else if(this.customer.customer_type == 3 && 
                    this.customer.entity_name == '' 
            ){
                message = this.lang.entity_name_required
                doIt = false;
            }

            if(this.customer.customer_type == 1 && this.customer.full_name == ''){
                this.customer.full_name = "عميل نقدي";
            }
            if(this.customer.customer_type == 1 && this.customer.mobile == ''){
                this.customer.mobile = "0500000000";
            }

            if(!doIt){
                this.$snotify.error(message, this.lang.alert, {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                return false;
            }else{
                this.$refs.InvoiceAddApprove.show();
            }
        }
    },
    created() {
        this.getStores();
    },
}

</script>
<style>
.customerTitle{
    border-radius:0;background:transparent;color:#fff;border:none;
}
.customerIndiv{
    margin-inline-end:5px;font-weight: bolder;width:150px;font-size:1.1rem !important;margin-inline-end:5px;border:2px solid #fff !important
}
.customerComp{
    margin-inline-end:5px;font-weight: bolder;width:150px;font-size:1.1rem !important;border:2px solid #fff !important
}
.customerGov{
    margin-inline-end:5px;font-weight: bolder;width:150px;font-size:1.1rem !important;border:2px solid #fff !important
}
.dispFlex{
    display:flex;
    flex-direction: row;
    justify-content: center !important;
    padding-left:10px;
    padding-right:10px;
    justify-items: center;
    align-items: center;
}
.boDry:hover{
    background:green !important;
}
.addCar{
    display:flex;
    flex-direction: row;
    justify-content: space-between;
}
.tobbuttom {
    display: flex;
    flex-direction: row;
    justify-content: center;
    justify-items: center;
    align-items: center;
}
.redColor {
    background: red!important;
    color: #fff!important;
}
.weightColor {
    background: #fff !important;
    color: #000 !important;
}
.borderRad_none, .borderRad_right {
    border-top-left-radius: 0 0!important;
    border-bottom-left-radius: 0 0!important;
    padding: 5px 10px!important;
    font-size: .8rem!important;
}
.hideme{
    display:none !important;
}
.borderRad_none {
    border-top-right-radius: 0 0!important;
    border-bottom-right-radius: 0 0!important;
}
.borderRad_left {
    border-top-left-radius: 5px 5px!important;
    border-bottom-left-radius: 5px 5px!important;
    border-top-right-radius: 0!important;
    border-bottom-right-radius: 0!important;
    padding: 5px 10px!important;
    font-size: .8rem!important;
    -webkit-margin-start: 5px!important;
    margin-inline-start: 5px!important;
}
</style>